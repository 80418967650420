/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
};

export type AnswerQuestionResponse = {
  __typename?: "AnswerQuestionResponse";
  questionId: Scalars["String"]["output"];
  response: QuizResponse;
};

export type Category = {
  __typename?: "Category";
  EMQ: Array<QuizQuestion>;
  MCQ: Array<QuizQuestion>;
  SAQ: Array<QuizQuestion>;
  flashcards: Array<Flashcard>;
  flashcardsCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  rootId: Scalars["ID"]["output"];
  saqPointsAvailable: Scalars["Int"]["output"];
};

export type CategoryId = {
  __typename?: "CategoryId";
  rootId: Scalars["String"]["output"];
  subcategoryId: Scalars["String"]["output"];
};

export type CategoryIdInput = {
  rootId: Scalars["String"]["input"];
  subcategoryId: Scalars["String"]["input"];
};

export type Config = {
  __typename?: "Config";
  maxContactUsLength: Scalars["Int"]["output"];
  maxExamCustomTopics: Scalars["Int"]["output"];
  maxExamResultsCommentFieldLength: Scalars["Int"]["output"];
  maxExamSessionsPerTopic: Scalars["Int"]["output"];
  maxExams: Scalars["Int"]["output"];
  maxQuizEntriesPerType: Scalars["Int"]["output"];
  maxReportLength: Scalars["Int"]["output"];
  maxTextFieldLength: Scalars["Int"]["output"];
  welcomeVideo: Scalars["String"]["output"];
};

export type CorrectQuizResponse = {
  __typename?: "CorrectQuizResponse";
  correctAnswers: Array<Scalars["String"]["output"]>;
  isCorrect: Scalars["Boolean"]["output"];
};

export type CreateExamInput = {
  customTopics: Array<Scalars["String"]["input"]>;
  dateDue: Scalars["Date"]["input"];
  name: Scalars["String"]["input"];
  topics: Array<CategoryIdInput>;
};

export type CreateExamSessionInput = {
  confidence: ExamConfidence;
  customTopic?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["Date"]["input"];
  examId: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  topic?: InputMaybe<CategoryIdInput>;
};

export type DeleteDataInput = {
  delete: Array<Feature>;
};

export enum Difficulty {
  Complete = "Complete",
  Easy = "Easy",
  Hard = "Hard",
  Medium = "Medium",
}

export enum Display {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export type Exam = {
  __typename?: "Exam";
  createdOn: Scalars["Date"]["output"];
  customTopics: Array<Scalars["String"]["output"]>;
  dateDue: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  result?: Maybe<ExamResult>;
  resultComment?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  topics: Array<CategoryId>;
};

export enum ExamConfidence {
  High = "High",
  Low = "Low",
  Medium = "Medium",
  VeryLow = "VeryLow",
}

export enum ExamResult {
  Fail = "Fail",
  Pass = "Pass",
}

export type ExamSession = {
  __typename?: "ExamSession";
  confidence: ExamConfidence;
  customTopic?: Maybe<Scalars["String"]["output"]>;
  date: Scalars["Date"]["output"];
  examId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  topic?: Maybe<CategoryId>;
};

export type Explanation = {
  __typename?: "Explanation";
  more?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  youtubeId?: Maybe<Scalars["String"]["output"]>;
};

export type Faq = {
  __typename?: "FAQ";
  answer: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type FactTrainerMetadata = {
  __typename?: "FactTrainerMetadata";
  responsesUrl: Scalars["String"]["output"];
};

export type FactTrainerResponse = {
  __typename?: "FactTrainerResponse";
  difficulty: Difficulty;
  updatedAt: Scalars["Date"]["output"];
};

export enum Feature {
  Emq = "EMQ",
  EmqPinned = "EMQPinned",
  FactTrainer = "FactTrainer",
  Flashcards = "Flashcards",
  HowToLearn = "HowToLearn",
  LocalStorage = "LocalStorage",
  Mcq = "MCQ",
  McqPinned = "MCQPinned",
  Osce = "OSCE",
  Saq = "SAQ",
  SaqPinned = "SAQPinned",
  Tracking = "Tracking",
}

export type Flashcard = {
  __typename?: "Flashcard";
  back: Scalars["String"]["output"];
  front: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type HowToUse = {
  __typename?: "HowToUse";
  slides: Array<HowToUseSlide>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type HowToUseSlide = {
  __typename?: "HowToUseSlide";
  content: Scalars["String"]["output"];
  display: Array<Display>;
};

export type IncorrectQuizResponse = {
  __typename?: "IncorrectQuizResponse";
  actualAnswers: Array<Scalars["String"]["output"]>;
  correctAnswers: Array<Scalars["String"]["output"]>;
  incorrectAnswers: Array<Scalars["String"]["output"]>;
  isCorrect: Scalars["Boolean"]["output"];
};

export type Lesson = {
  __typename?: "Lesson";
  chapter: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  topic: Scalars["String"]["output"];
  youtubeId?: Maybe<Scalars["String"]["output"]>;
};

export type MembershipBenefit = {
  __typename?: "MembershipBenefit";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  image: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  answerQuestion: AnswerQuestionResponse;
  clearPinnedResponses: Scalars["Boolean"]["output"];
  createExam: Exam;
  createExamSession: ExamSession;
  deleteData: Scalars["Boolean"]["output"];
  deleteExam: Scalars["Boolean"]["output"];
  deleteExamSession: Scalars["Boolean"]["output"];
  deletePinnedQuizAttempt: Scalars["Boolean"]["output"];
  deleteQuizAttempt: Scalars["Boolean"]["output"];
  deleteRepeatIncorrectQuizAttempt: Scalars["Boolean"]["output"];
  pinFlashcard: Scalars["Boolean"]["output"];
  pinQuestion: Scalars["Boolean"]["output"];
  report: Scalars["Boolean"]["output"];
  reviewFactTrainerQuestion: FactTrainerResponse;
  saveOSCEScore: Scalars["Boolean"]["output"];
  setExamResult: Scalars["Boolean"]["output"];
  setLessonCompleted: Scalars["Boolean"]["output"];
  startNewQuizAttempt: QuizAttemptMetadata;
  submitQuestion: Scalars["Boolean"]["output"];
  unpinFlashcard: Scalars["Boolean"]["output"];
  unpinFlashcardsByCategories: Array<Scalars["String"]["output"]>;
  unpinQuestion: Scalars["Boolean"]["output"];
  unpinQuestionsByCategories: Array<Scalars["String"]["output"]>;
  updateExam: Exam;
};

export type MutationAnswerQuestionArgs = {
  answer: Array<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  sessionType: SessionType;
  type: QuizType;
};

export type MutationClearPinnedResponsesArgs = {
  type: QuizType;
};

export type MutationCreateExamArgs = {
  input: CreateExamInput;
};

export type MutationCreateExamSessionArgs = {
  input: CreateExamSessionInput;
};

export type MutationDeleteDataArgs = {
  input: DeleteDataInput;
};

export type MutationDeleteExamArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteExamSessionArgs = {
  examId: Scalars["ID"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationDeletePinnedQuizAttemptArgs = {
  type: QuizType;
};

export type MutationDeleteQuizAttemptArgs = {
  id: Scalars["ID"]["input"];
  type: QuizType;
};

export type MutationDeleteRepeatIncorrectQuizAttemptArgs = {
  type: QuizType;
};

export type MutationPinFlashcardArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationPinQuestionArgs = {
  id: Scalars["ID"]["input"];
  type: QuizType;
};

export type MutationReportArgs = {
  message: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

export type MutationReviewFactTrainerQuestionArgs = {
  difficulty: Difficulty;
  id: Scalars["ID"]["input"];
};

export type MutationSaveOsceScoreArgs = {
  input: SaveOsceScoreInput;
};

export type MutationSetExamResultArgs = {
  input: SetExamResultInput;
};

export type MutationSetLessonCompletedArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationStartNewQuizAttemptArgs = {
  type: QuizType;
};

export type MutationSubmitQuestionArgs = {
  message: Scalars["String"]["input"];
};

export type MutationUnpinFlashcardArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUnpinFlashcardsByCategoriesArgs = {
  categoryIds?: InputMaybe<Array<CategoryIdInput>>;
};

export type MutationUnpinQuestionArgs = {
  id: Scalars["ID"]["input"];
  type: QuizType;
};

export type MutationUnpinQuestionsByCategoriesArgs = {
  categoryIds?: InputMaybe<Array<CategoryIdInput>>;
  type: QuizType;
};

export type MutationUpdateExamArgs = {
  input: UpdateExamInput;
};

export type NavigationItem = {
  __typename?: "NavigationItem";
  destination: Scalars["String"]["output"];
  hideWhenUnauthenticated?: Maybe<Scalars["Boolean"]["output"]>;
  homepage?: Maybe<NavigationItemHomepageConfig>;
  title: Scalars["String"]["output"];
};

export type NavigationItemHomepageConfig = {
  __typename?: "NavigationItemHomepageConfig";
  image?: Maybe<Scalars["String"]["output"]>;
  show?: Maybe<Scalars["Boolean"]["output"]>;
  width?: Maybe<Scalars["String"]["output"]>;
};

export type OsceResult = {
  __typename?: "OSCEResult";
  clinicalSignsScore: Scalars["Float"]["output"];
  date: Scalars["Date"]["output"];
  pointScore: Scalars["Float"]["output"];
  stationId: Scalars["ID"]["output"];
};

export enum Page {
  Emq = "EMQ",
  FactTrainer = "FactTrainer",
  Flashcards = "Flashcards",
  HowToLearn = "HowToLearn",
  Mcq = "MCQ",
  Osce = "OSCE",
  Results = "Results",
  Saq = "SAQ",
  Tracking = "Tracking",
}

export type PinnedAttemptMetadata = {
  __typename?: "PinnedAttemptMetadata";
  responsesUrl: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  completedLessonIds: Array<Scalars["ID"]["output"]>;
  completedRepeatIncorrectQuestionIds: Array<Scalars["ID"]["output"]>;
  config: Config;
  currentQuizAttemptMetadata?: Maybe<QuizAttemptMetadata>;
  examById?: Maybe<Exam>;
  examSessions: Array<ExamSession>;
  exams: Array<Exam>;
  factTrainerMetadata?: Maybe<FactTrainerMetadata>;
  faqs: Array<Faq>;
  flashcardCategories: Array<Maybe<Category>>;
  flashcards: Array<Flashcard>;
  howToUse?: Maybe<HowToUse>;
  lessonById?: Maybe<Lesson>;
  membershipBenefits: Array<MembershipBenefit>;
  navigationItems: Array<NavigationItem>;
  osceScores?: Maybe<Array<OsceResult>>;
  pinnedFlashcardIds: Array<Scalars["ID"]["output"]>;
  pinnedQuestionIds: Array<Scalars["ID"]["output"]>;
  questionById?: Maybe<QuizQuestion>;
  quizAttemptsMetadata: Array<QuizAttemptMetadata>;
  quizCategories: Array<Category>;
  repeatQuestions: Array<QuizQuestion>;
};

export type QueryCompletedRepeatIncorrectQuestionIdsArgs = {
  attemptId: Scalars["ID"]["input"];
  type: QuizType;
};

export type QueryCurrentQuizAttemptMetadataArgs = {
  sessionType?: InputMaybe<SessionType>;
  type: QuizType;
};

export type QueryExamByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryExamSessionsArgs = {
  examId: Scalars["ID"]["input"];
};

export type QueryFlashcardsArgs = {
  isPinned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryHowToUseArgs = {
  page: Page;
};

export type QueryLessonByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPinnedQuestionIdsArgs = {
  type: QuizType;
};

export type QueryQuestionByIdArgs = {
  id: Scalars["ID"]["input"];
  type: QuizType;
};

export type QueryQuizAttemptsMetadataArgs = {
  type: QuizType;
};

export type QueryQuizCategoriesArgs = {
  type?: InputMaybe<QuizType>;
};

export type QueryRepeatQuestionsArgs = {
  type: QuizType;
};

export type QuizAttemptMetadata = {
  __typename?: "QuizAttemptMetadata";
  id: Scalars["ID"]["output"];
  responsesUrl: Scalars["String"]["output"];
};

export type QuizQuestion = {
  __typename?: "QuizQuestion";
  answers: Array<Scalars["String"]["output"]>;
  explanation?: Maybe<Explanation>;
  id: Scalars["ID"]["output"];
  question: Scalars["String"]["output"];
  type: QuizType;
};

export type QuizResponse = CorrectQuizResponse | IncorrectQuizResponse;

export enum QuizType {
  Emq = "EMQ",
  Mcq = "MCQ",
  Saq = "SAQ",
}

export type SaveOsceScoreInput = {
  clinicalSignsScore: Scalars["Float"]["input"];
  pointScore: Scalars["Float"]["input"];
  stationId: Scalars["ID"]["input"];
};

export enum SessionType {
  Pinned = "Pinned",
  RepeatIncorrect = "RepeatIncorrect",
  Standard = "Standard",
}

export type SetExamResultInput = {
  comment: Scalars["String"]["input"];
  examId: Scalars["ID"]["input"];
  result: ExamResult;
};

export type UpdateExamInput = {
  customTopics: Array<Scalars["String"]["input"]>;
  dateDue: Scalars["Date"]["input"];
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  topics: Array<CategoryIdInput>;
};

export type ReportMutationVariables = Exact<{
  url: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
}>;

export type ReportMutation = { __typename?: "Mutation"; report: boolean };

export type AnswerQuestionMutationVariables = Exact<{
  type: QuizType;
  id: Scalars["ID"]["input"];
  answer: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  sessionType: SessionType;
}>;

export type AnswerQuestionMutation = {
  __typename?: "Mutation";
  answerQuestion: {
    __typename?: "AnswerQuestionResponse";
    questionId: string;
    response:
      | { __typename?: "CorrectQuizResponse"; isCorrect: boolean; correctAnswers: Array<string> }
      | {
          __typename?: "IncorrectQuizResponse";
          isCorrect: boolean;
          correctAnswers: Array<string>;
          incorrectAnswers: Array<string>;
          actualAnswers: Array<string>;
        };
  };
};

export type CreateExamMutationVariables = Exact<{
  input: CreateExamInput;
}>;

export type CreateExamMutation = {
  __typename?: "Mutation";
  createExam: { __typename?: "Exam"; id: string; createdOn: any };
};

export type CreateExamSessionMutationVariables = Exact<{
  input: CreateExamSessionInput;
}>;

export type CreateExamSessionMutation = {
  __typename?: "Mutation";
  createExamSession: { __typename?: "ExamSession"; id: string };
};

export type DeleteDataMutationVariables = Exact<{
  input: DeleteDataInput;
}>;

export type DeleteDataMutation = { __typename?: "Mutation"; deleteData: boolean };

export type DeleteExamMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteExamMutation = { __typename?: "Mutation"; deleteExam: boolean };

export type DeleteExamSessionMutationVariables = Exact<{
  examId: Scalars["ID"]["input"];
  sessionId: Scalars["ID"]["input"];
}>;

export type DeleteExamSessionMutation = { __typename?: "Mutation"; deleteExamSession: boolean };

export type DeletePinnedQuizAttemptMutationVariables = Exact<{
  type: QuizType;
}>;

export type DeletePinnedQuizAttemptMutation = { __typename?: "Mutation"; deletePinnedQuizAttempt: boolean };

export type DeleteQuizAttemptMutationVariables = Exact<{
  type: QuizType;
  id: Scalars["ID"]["input"];
}>;

export type DeleteQuizAttemptMutation = { __typename?: "Mutation"; deleteQuizAttempt: boolean };

export type DeleteRepeatIncorrectQuizAttemptMutationVariables = Exact<{
  type: QuizType;
}>;

export type DeleteRepeatIncorrectQuizAttemptMutation = {
  __typename?: "Mutation";
  deleteRepeatIncorrectQuizAttempt: boolean;
};

export type PinFlashcardByIdMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PinFlashcardByIdMutation = { __typename?: "Mutation"; pinFlashcard: boolean };

export type ReviewFactTrainerQuestionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  difficulty: Difficulty;
}>;

export type ReviewFactTrainerQuestionMutation = {
  __typename?: "Mutation";
  reviewFactTrainerQuestion: { __typename?: "FactTrainerResponse"; updatedAt: any };
};

export type SetExamResultMutationVariables = Exact<{
  input: SetExamResultInput;
}>;

export type SetExamResultMutation = { __typename?: "Mutation"; setExamResult: boolean };

export type SetLessonCompletedMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  completed: Scalars["Boolean"]["input"];
}>;

export type SetLessonCompletedMutation = { __typename?: "Mutation"; setLessonCompleted: boolean };

export type StartNewQuizAttemptMutationVariables = Exact<{
  type: QuizType;
}>;

export type StartNewQuizAttemptMutation = {
  __typename?: "Mutation";
  startNewQuizAttempt: { __typename?: "QuizAttemptMetadata"; id: string; responsesUrl: string };
};

export type UnpinFlashcardByIdMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UnpinFlashcardByIdMutation = { __typename?: "Mutation"; unpinFlashcard: boolean };

export type UnpinFlashcardsByCategoriesMutationVariables = Exact<{
  categoryIds?: InputMaybe<Array<CategoryIdInput> | CategoryIdInput>;
}>;

export type UnpinFlashcardsByCategoriesMutation = {
  __typename?: "Mutation";
  unpinFlashcardsByCategories: Array<string>;
};

export type UnpinQuestionsByCategoriesMutationVariables = Exact<{
  type: QuizType;
  categoryIds?: InputMaybe<Array<CategoryIdInput> | CategoryIdInput>;
}>;

export type UnpinQuestionsByCategoriesMutation = { __typename?: "Mutation"; unpinQuestionsByCategories: Array<string> };

export type UpdateExamMutationVariables = Exact<{
  input: UpdateExamInput;
}>;

export type UpdateExamMutation = { __typename?: "Mutation"; updateExam: { __typename?: "Exam"; id: string } };

export type GetCompletedLessonIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompletedLessonIdsQuery = { __typename?: "Query"; completedLessonIds: Array<string> };

export type GetExamByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetExamByIdQuery = {
  __typename?: "Query";
  examById?: {
    __typename?: "Exam";
    id: string;
    createdOn: any;
    dateDue: any;
    title: string;
    customTopics: Array<string>;
    result?: ExamResult | null;
    resultComment?: string | null;
    topics: Array<{ __typename?: "CategoryId"; rootId: string; subcategoryId: string }>;
  } | null;
};

export type GetExamSessionsQueryVariables = Exact<{
  examId: Scalars["ID"]["input"];
}>;

export type GetExamSessionsQuery = {
  __typename?: "Query";
  examSessions: Array<{
    __typename?: "ExamSession";
    id: string;
    examId: string;
    date: any;
    confidence: ExamConfidence;
    notes?: string | null;
    customTopic?: string | null;
    topic?: { __typename?: "CategoryId"; rootId: string; subcategoryId: string } | null;
  }>;
};

export type GetExamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExamsQuery = {
  __typename?: "Query";
  exams: Array<{
    __typename?: "Exam";
    id: string;
    createdOn: any;
    dateDue: any;
    title: string;
    customTopics: Array<string>;
    result?: ExamResult | null;
    resultComment?: string | null;
    topics: Array<{ __typename?: "CategoryId"; rootId: string; subcategoryId: string }>;
  }>;
};

export type GetMembershipBenefitQueryVariables = Exact<{ [key: string]: never }>;

export type GetMembershipBenefitQuery = {
  __typename?: "Query";
  membershipBenefits: Array<{
    __typename?: "MembershipBenefit";
    id: string;
    title: string;
    description: string;
    image: string;
    width?: number | null;
  }>;
};

export type GetOsceScoresQueryVariables = Exact<{ [key: string]: never }>;

export type GetOsceScoresQuery = {
  __typename?: "Query";
  osceScores?: Array<{
    __typename?: "OSCEResult";
    date: any;
    pointScore: number;
    clinicalSignsScore: number;
    stationId: string;
  }> | null;
};

export type GetPinnedFlashcardIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPinnedFlashcardIdsQuery = { __typename?: "Query"; pinnedFlashcardIds: Array<string> };

export type GetPinnedQuestionIdsQueryVariables = Exact<{
  type: QuizType;
}>;

export type GetPinnedQuestionIdsQuery = { __typename?: "Query"; pinnedQuestionIds: Array<string> };

export type GetCurrentQuizAttemptMetadataQueryVariables = Exact<{
  type: QuizType;
  sessionType?: InputMaybe<SessionType>;
}>;

export type GetCurrentQuizAttemptMetadataQuery = {
  __typename?: "Query";
  currentQuizAttemptMetadata?: { __typename?: "QuizAttemptMetadata"; id: string; responsesUrl: string } | null;
};

export type GetFactTrainerMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type GetFactTrainerMetadataQuery = {
  __typename?: "Query";
  factTrainerMetadata?: { __typename?: "FactTrainerMetadata"; responsesUrl: string } | null;
};

export type GetQuizAttemptsMetadataQueryVariables = Exact<{
  type: QuizType;
}>;

export type GetQuizAttemptsMetadataQuery = {
  __typename?: "Query";
  quizAttemptsMetadata: Array<{ __typename?: "QuizAttemptMetadata"; id: string; responsesUrl: string }>;
};

export type CompletedRepeatIncorrectQuestionIdsQueryVariables = Exact<{
  type: QuizType;
  attemptId: Scalars["ID"]["input"];
}>;

export type CompletedRepeatIncorrectQuestionIdsQuery = {
  __typename?: "Query";
  completedRepeatIncorrectQuestionIds: Array<string>;
};

export type SubmitQuestionMutationVariables = Exact<{
  message: Scalars["String"]["input"];
}>;

export type SubmitQuestionMutation = { __typename?: "Mutation"; submitQuestion: boolean };

export type SaveOsceScoreMutationVariables = Exact<{
  input: SaveOsceScoreInput;
}>;

export type SaveOsceScoreMutation = { __typename?: "Mutation"; saveOSCEScore: boolean };

export type GetQuestionTitleQueryVariables = Exact<{
  type: QuizType;
  id: Scalars["ID"]["input"];
}>;

export type GetQuestionTitleQuery = {
  __typename?: "Query";
  questionById?: { __typename?: "QuizQuestion"; question: string; answers: Array<string> } | null;
};

export type PinQuestionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  type: QuizType;
}>;

export type PinQuestionMutation = { __typename?: "Mutation"; pinQuestion: boolean };

export type UnpinQuestionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  type: QuizType;
}>;

export type UnpinQuestionMutation = { __typename?: "Mutation"; unpinQuestion: boolean };

export const ReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Report" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "message" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "report" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "url" },
                value: { kind: "Variable", name: { kind: "Name", value: "url" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: { kind: "Variable", name: { kind: "Name", value: "message" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportMutation, ReportMutationVariables>;
export const AnswerQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AnswerQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "answer" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sessionType" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SessionType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "answerQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "answer" },
                value: { kind: "Variable", name: { kind: "Name", value: "answer" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sessionType" },
                value: { kind: "Variable", name: { kind: "Name", value: "sessionType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "questionId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "response" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CorrectQuizResponse" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "isCorrect" } },
                            { kind: "Field", name: { kind: "Name", value: "correctAnswers" } },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "IncorrectQuizResponse" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "isCorrect" } },
                            { kind: "Field", name: { kind: "Name", value: "correctAnswers" } },
                            { kind: "Field", name: { kind: "Name", value: "incorrectAnswers" } },
                            { kind: "Field", name: { kind: "Name", value: "actualAnswers" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnswerQuestionMutation, AnswerQuestionMutationVariables>;
export const CreateExamDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateExam" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "CreateExamInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createExam" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdOn" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateExamMutation, CreateExamMutationVariables>;
export const CreateExamSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateExamSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateExamSessionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createExamSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateExamSessionMutation, CreateExamSessionMutationVariables>;
export const DeleteDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DeleteDataInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDataMutation, DeleteDataMutationVariables>;
export const DeleteExamDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteExam" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteExam" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteExamMutation, DeleteExamMutationVariables>;
export const DeleteExamSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteExamSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "examId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteExamSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "examId" },
                value: { kind: "Variable", name: { kind: "Name", value: "examId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sessionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteExamSessionMutation, DeleteExamSessionMutationVariables>;
export const DeletePinnedQuizAttemptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeletePinnedQuizAttempt" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePinnedQuizAttempt" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePinnedQuizAttemptMutation, DeletePinnedQuizAttemptMutationVariables>;
export const DeleteQuizAttemptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteQuizAttempt" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteQuizAttempt" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteQuizAttemptMutation, DeleteQuizAttemptMutationVariables>;
export const DeleteRepeatIncorrectQuizAttemptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteRepeatIncorrectQuizAttempt" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteRepeatIncorrectQuizAttempt" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRepeatIncorrectQuizAttemptMutation,
  DeleteRepeatIncorrectQuizAttemptMutationVariables
>;
export const PinFlashcardByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PinFlashcardById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pinFlashcard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinFlashcardByIdMutation, PinFlashcardByIdMutationVariables>;
export const ReviewFactTrainerQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ReviewFactTrainerQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "difficulty" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Difficulty" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewFactTrainerQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "difficulty" },
                value: { kind: "Variable", name: { kind: "Name", value: "difficulty" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "updatedAt" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewFactTrainerQuestionMutation, ReviewFactTrainerQuestionMutationVariables>;
export const SetExamResultDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetExamResult" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SetExamResultInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setExamResult" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetExamResultMutation, SetExamResultMutationVariables>;
export const SetLessonCompletedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetLessonCompleted" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "completed" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setLessonCompleted" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "completed" },
                value: { kind: "Variable", name: { kind: "Name", value: "completed" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetLessonCompletedMutation, SetLessonCompletedMutationVariables>;
export const StartNewQuizAttemptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartNewQuizAttempt" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startNewQuizAttempt" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "responsesUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartNewQuizAttemptMutation, StartNewQuizAttemptMutationVariables>;
export const UnpinFlashcardByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnpinFlashcardById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unpinFlashcard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinFlashcardByIdMutation, UnpinFlashcardByIdMutationVariables>;
export const UnpinFlashcardsByCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnpinFlashcardsByCategories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "categoryIds" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CategoryIdInput" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unpinFlashcardsByCategories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryIds" },
                value: { kind: "Variable", name: { kind: "Name", value: "categoryIds" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinFlashcardsByCategoriesMutation, UnpinFlashcardsByCategoriesMutationVariables>;
export const UnpinQuestionsByCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnpinQuestionsByCategories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "categoryIds" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CategoryIdInput" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unpinQuestionsByCategories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryIds" },
                value: { kind: "Variable", name: { kind: "Name", value: "categoryIds" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinQuestionsByCategoriesMutation, UnpinQuestionsByCategoriesMutationVariables>;
export const UpdateExamDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateExam" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UpdateExamInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateExam" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateExamMutation, UpdateExamMutationVariables>;
export const GetCompletedLessonIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompletedLessonIds" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "completedLessonIds" } }],
      },
    },
  ],
} as unknown as DocumentNode<GetCompletedLessonIdsQuery, GetCompletedLessonIdsQueryVariables>;
export const GetExamByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExamById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "examById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdOn" } },
                { kind: "Field", name: { kind: "Name", value: "dateDue" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topics" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rootId" } },
                      { kind: "Field", name: { kind: "Name", value: "subcategoryId" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "customTopics" } },
                { kind: "Field", name: { kind: "Name", value: "result" } },
                { kind: "Field", name: { kind: "Name", value: "resultComment" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExamByIdQuery, GetExamByIdQueryVariables>;
export const GetExamSessionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExamSessions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "examId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "examSessions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "examId" },
                value: { kind: "Variable", name: { kind: "Name", value: "examId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "examId" } },
                { kind: "Field", name: { kind: "Name", value: "date" } },
                { kind: "Field", name: { kind: "Name", value: "confidence" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rootId" } },
                      { kind: "Field", name: { kind: "Name", value: "subcategoryId" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "customTopic" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExamSessionsQuery, GetExamSessionsQueryVariables>;
export const GetExamsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExams" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exams" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdOn" } },
                { kind: "Field", name: { kind: "Name", value: "dateDue" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topics" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rootId" } },
                      { kind: "Field", name: { kind: "Name", value: "subcategoryId" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "customTopics" } },
                { kind: "Field", name: { kind: "Name", value: "result" } },
                { kind: "Field", name: { kind: "Name", value: "resultComment" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExamsQuery, GetExamsQueryVariables>;
export const GetMembershipBenefitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetMembershipBenefit" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipBenefits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMembershipBenefitQuery, GetMembershipBenefitQueryVariables>;
export const GetOsceScoresDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOsceScores" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "osceScores" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "date" } },
                { kind: "Field", name: { kind: "Name", value: "pointScore" } },
                { kind: "Field", name: { kind: "Name", value: "clinicalSignsScore" } },
                { kind: "Field", name: { kind: "Name", value: "stationId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOsceScoresQuery, GetOsceScoresQueryVariables>;
export const GetPinnedFlashcardIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPinnedFlashcardIds" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "pinnedFlashcardIds" } }],
      },
    },
  ],
} as unknown as DocumentNode<GetPinnedFlashcardIdsQuery, GetPinnedFlashcardIdsQueryVariables>;
export const GetPinnedQuestionIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPinnedQuestionIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pinnedQuestionIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPinnedQuestionIdsQuery, GetPinnedQuestionIdsQueryVariables>;
export const GetCurrentQuizAttemptMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentQuizAttemptMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sessionType" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SessionType" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentQuizAttemptMetadata" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sessionType" },
                value: { kind: "Variable", name: { kind: "Name", value: "sessionType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "responsesUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentQuizAttemptMetadataQuery, GetCurrentQuizAttemptMetadataQueryVariables>;
export const GetFactTrainerMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFactTrainerMetadata" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "factTrainerMetadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "responsesUrl" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFactTrainerMetadataQuery, GetFactTrainerMetadataQueryVariables>;
export const GetQuizAttemptsMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetQuizAttemptsMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quizAttemptsMetadata" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "responsesUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQuizAttemptsMetadataQuery, GetQuizAttemptsMetadataQueryVariables>;
export const CompletedRepeatIncorrectQuestionIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "completedRepeatIncorrectQuestionIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "attemptId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completedRepeatIncorrectQuestionIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attemptId" },
                value: { kind: "Variable", name: { kind: "Name", value: "attemptId" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompletedRepeatIncorrectQuestionIdsQuery,
  CompletedRepeatIncorrectQuestionIdsQueryVariables
>;
export const SubmitQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "message" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: { kind: "Variable", name: { kind: "Name", value: "message" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitQuestionMutation, SubmitQuestionMutationVariables>;
export const SaveOsceScoreDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SaveOSCEScore" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SaveOSCEScoreInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveOSCEScore" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveOsceScoreMutation, SaveOsceScoreMutationVariables>;
export const GetQuestionTitleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetQuestionTitle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "questionById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "answers" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQuestionTitleQuery, GetQuestionTitleQueryVariables>;
export const PinQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PinQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pinQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinQuestionMutation, PinQuestionMutationVariables>;
export const UnpinQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnpinQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unpinQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "Variable", name: { kind: "Name", value: "type" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinQuestionMutation, UnpinQuestionMutationVariables>;
